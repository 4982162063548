import React, {useEffect, useState} from 'react'
import {default as completeCheck} from '../assets/complete-check.svg'
import axios from "axios";
import {Loader} from "./loader/loader";

type FintecPayment = {
    sender_holder: string;
    sender_iban: string;
    sender_bic: string;
    sender_bank_name: string;
    sender_country_id: string;
}

export const SuccessPage: React.FC = () => {

    const [payment, setPayment] = useState<FintecPayment>({
        sender_holder: '',
        sender_iban: '',
        sender_bic: '',
        sender_bank_name: '',
        sender_country_id: ''
    })
    const [loading, setLoading] = useState(true)

    const getPayment = async (): Promise<void> => {
        try {
            const response = await axios({
                method: 'GET',
                url: 'https://fintec-poc-api.vercel.app/api/get-payment/' + localStorage.getItem('transactionId'),
            })
            setPayment(response.data)
            setLoading(false)
        } catch (error) {
            throw(error)
        }
    }

    useEffect(() => {
        getPayment()
    }, [])

    return (
        loading ? (
                <div className="loader">
                    <Loader />
                </div>
            )
            :
            (
                <div className="container">
                    <div className="content message">
                        <div className="line centered">
                            <img src={completeCheck}/>
                            <h1>Success</h1>
                        </div>
                        <div>
                            <table className="result-table">
                                <tr>
                                    <td>Sender holder</td>
                                    <td>{payment.sender_holder}</td>
                                </tr>
                                <tr>
                                    <td>Sender Iban</td>
                                    <td>{payment.sender_iban}</td>
                                </tr>
                                <tr>
                                    <td>Sender BIC</td>
                                    <td>{payment.sender_bic}</td>
                                </tr>
                                <tr>
                                    <td>Sender Bank Name</td>
                                    <td>{payment.sender_bank_name}</td>
                                </tr>
                                <tr>
                                    <td>Sender Country Id</td>
                                    <td>{payment.sender_country_id}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            )
    )
}