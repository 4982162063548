import React from 'react'

export const ErrorPage: React.FC = () => {
  return (
      <div className="container">
          <div className="content message">
              <div className="line centered">
                  <span className="error-icon" />
              </div>
              <h1>An error occured</h1>
              <div className="line centered">
                  Please try again later...
              </div>
          </div>
      </div>
  )
}
