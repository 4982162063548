import React, {useEffect, useState} from "react";
import { useHistory } from 'react-router-dom';
import axios from "axios";

type FintecPayment = {
    wizard_session_key: string;
    transaction: string;
}

export const Fintec: React.FC = () => {

    let history = useHistory();
    const [wizardSessionKey, setWizardSessionKey] = useState('');
    const [snapshot, setSnapshot] = useState(null);

    const getPayment = async (): Promise<FintecPayment> => {
        try {
            const response = await axios({
                method: 'POST',
                url: 'https://fintec-poc-api.vercel.app/api/create-payment',
            })
            return response.data
        } catch (error) {
            throw(error)
        }
    }

    const handlePayment = async (): Promise<void> => {
        try {
            const credentials = await getPayment()

            if (credentials && credentials['wizard_session_key'].length > 0) {
                setWizardSessionKey(credentials['wizard_session_key'])
                localStorage.setItem('transactionId', credentials['transaction'])
                const xs2sElement = document.getElementById('XS2A-Form');
                xs2sElement!.setAttribute('data-xs2a', credentials['wizard_session_key'])
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getSnapshot = async (): Promise<void> => {
        try {
            const response = await axios({
                method: 'GET',
                url: 'https://fintec-poc-api.vercel.app/api/get-snapshot/' + localStorage.getItem('transactionId'),
            })
            setSnapshot(response.data)
            console.log(response.data)
        } catch (error) {
            throw(error)
        }
    }

    const xs2a = (window as any).xs2a;

    useEffect(() => {
        handlePayment();
    }, [])

    useEffect(() => {
        if (wizardSessionKey !== '') {
            xs2a.finish(function () {
                history.push('/success');
            });
            xs2a.abort(function () {
                history.push('/error');
            });
            xs2a.configure({
                'reuse-tab-on-redirect': false
            });
            xs2a.intermission(function() {
               console.log("Transaction in intermission state")
                getSnapshot()
            });
            xs2a.init();
        }
    }, [wizardSessionKey])

    return (
        <div className="xs2a-container">
            <div id="XS2A-Form" data-xs2a=""/>
            {snapshot ?
            <div className="account-snapshot">
                <pre>
                    {JSON.stringify(snapshot[0], null, 4)}
                </pre>
            </div>
                : ""}
        </div>
    )
}
