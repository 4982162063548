import React, {useEffect, useState} from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.scss';
import {ErrorPage} from './errorPage';
import {Fintec} from './Fintec/Fintec';
import {SuccessPage} from './successPage';
import {Topbar} from "./topbar/component";

function App() {

  return (
      <div>
          <Topbar />
          <Switch>
              <Route exact path="/"><Fintec /></Route>
              <Route path="/error"><ErrorPage /></Route>
              <Route path="/success"><SuccessPage /></Route>
          </Switch>
      </div>
  );
}

export default App;
