import React from 'react'
import './styles.scss'

export const Loader: React.FC = (props) => {
  return (
    <div className="loading-container">
      <span className="loading-spinner" />
    </div>
  )
}
