import React from 'react'

import signicatLogo from '../../assets/signicat-logo.png'
import identityIcon from '../../assets/icon.png';

import './styles.scss'

export const Topbar: React.FC = () => {

    return (
        <div className="topbar">
            <div className="topbar__img"><img src={signicatLogo} alt="logo"/></div>
            <div className="topbar__attempt">
                <div className="topbar__attempt--title">Micropayment</div>
                <div className="topbar__attempt--subtitle">Identity API</div>
            </div>
            <div className="topbar__icon">
                <img src={identityIcon} alt="logo"></img>
            </div>
        </div>
    )
}
